<template>
    <div class="content about" :class="[isMobile ? 'mobile' : '']">
        <navbar @openDialog="openDialog"></navbar>
        <div class="background flex-center margin-bottom flex-wrap justify-center">
            <div class="about-top-view">
                <!-- <div v-if="smallScreen">
                    <VueTyper @completed="completed" text='引领变革，智启未来' :typeDelay="150" :repeat="0"></VueTyper>
                    <VueTyper v-if="isCompleted" text='  AI驱动，创新无限' :typeDelay="150" :repeat="0"></VueTyper>
                </div>
                <div v-else> -->
                <VueTyper text='引领变革，智启未来，AI驱动，创新无限' :typeDelay="150" :repeat="0"></VueTyper>
                <!-- </div> -->
                <el-button @click="openDialog" class="text-lg text-blue solid radius-8 line-blue margin-left-sm" plain>
                    <i class="icon-detail"></i> 联系我们
                </el-button>
            </div>
        </div>
        <div class="video-view flex-center flex-wrap">
            <div v-animate="{ appearClass: 'animate__fadeInLeft' }" class="video-title text-center animate__animated">
                <div class="text-white text-bold">90秒看懂数智员工</div>
            </div>
            <div v-animate="{ appearClass: 'animate__fadeInRight' }" class="employee-video animate__animated">
                <!-- <video class="video" autoplay loop muted :src="video4"></video> -->
                <video-player class="video-player video vjs-custom-skin" ref="videoPlayer" :playsinline="true"
                    :options="playerOptions">
                </video-player>
            </div>
        </div>
        <div v-animate="{ appearClass: 'animate__fadeInUp' }"
            class="animate__animated about-middle-view justify-center flex-center text-white">
            <div class="item item-1">
                <div class="title">团结自省</div>
                <div>始终保持一颗自省之心，一份永不言败的信念，一种坚持不懈的毅力，团结一致，同舟共济。
                </div>
            </div>
            <div class="item item-2">
                <div class="title">实干创新</div>
                <div>以创新为动力，以实干为基石，我们致力于将科技转化为推动社会进步的力量。</div>
            </div>
            <div class="item item-3">
                <div class="title">分享感恩</div>
                <div>在分享中凝聚智慧，在感恩中汲取力量，以开放的心态培育团队，以感激的心情回馈客户。</div>
            </div>
        </div>
        <div v-animate="{ appearClass: 'animate__fadeInUp' }"
            class="animate__animated about-bottom-view flex-center justify-center">
            <div class="item">
                <div class="title">使命</div>
                <div>以数智人力解放生产力</div>
            </div>
            <div class="item" v-animate="{ appearClass: 'active' }">
                <div class="title">愿景</div>
                <div>成为全球最大的数智人力资源中心</div>
            </div>
        </div>
        <dynamic v-if="!isMobile"></dynamic>
        <foot></foot>
        <app-dialog :isMobile="isMobile" ref="dialog"></app-dialog>
    </div>
</template>

<script>
import navbar from '@/components/navbar.vue'
import Foot from '@/components/foot.vue'
import AppDialog from '@/components/appDialog.vue';
import dynamic from '@/components/dynamic.vue';
import { VueTyper } from 'vue-typer';
import _ from "lodash"
import { scrollTo } from '@/assets/js/scroll-to'
import { isMobile } from "@/utils";
export default {
    components: { navbar, Foot, AppDialog, VueTyper, dynamic },
    data() {
        return {
            isMobile: false,
            smallScreen: false,
            isCompleted: false,
            // 视频
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                    {
                        type: "video/mp4", // 类型
                        src: "https://oss.real-bot.cn/web/website/20250325-181027.m4v", // url地址
                    },
                ],
                poster: "https://oss.isuiyou.cn/scrm/6737028d-4f7b-45cf-93c9-f868358d2d84/20240228100939.png", // 封面地址
                notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: false, // 当前时间和持续时间的分隔符
                    durationDisplay: false, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true, // 是否显示全屏按钮
                }
            },
        }
    },
    methods: {
        openDialog() {
            this.$refs.dialog.open();
        },
        pageScroll() {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            console.log(scrollTop)
        },
        completed() {
            this.isCompleted = true;
        }
    },
    mounted() {
        scrollTo(0, 0, 0)
        this.isMobile = isMobile();
        this.smallScreen = window.innerWidth < 1700;
        // this.$nextTick(() => {
        //     window.addEventListener('resize', _.debounce((e) => {
        //         this.isMobile = window.innerWidth < 950;
        //         this.smallScreen = window.innerWidth < 1700;
        //     }), 150)
        // });
        // document.addEventListener("scroll", this.pageScroll);
    }
}
</script>

<style lang="scss" scoped>
.content ::v-deep {
    width: 100%;
    // height: 100%;
    background: #F5F5F7;

    .background {
        width: 100%;
        height: 100vh;
        font-size: 90px;
        font-weight: 800;

        .about-top-view {
            // width: 900px;
            text-align: center;

            .vue-typer {
                display: block;
                width: 100%;
            }
        }

        .el-button {
            color: #fff;
            font-size: 20px;
            width: 255px;
            height: 60px;
            border-radius: 390px;
            margin-top: 50px;
            background: #000000;

            &:hover {
                border-color: #000;
            }
        }
    }

    .video-view {
        width: 95%;
        height: 1050px;
        margin: 0 auto;
        background: #1D1D1F;
        border-radius: 50px;
        align-content: center;

        .video-title {
            width: 100%;
            font-size: 58px;
            margin-bottom: 120px;
        }

        .employee-video {
            width: 1024px;
            height: 576px;
            margin: 0 auto;
            display: block;
            box-shadow: 0px 8px 32px 0px rgba(175, 187, 220, 0.5);
        }
    }

    .about-middle-view {
        gap: 20px;
        margin: 120px;

        .item {
            width: 350px;
            height: 230px;
            border-radius: 8px;
            background-color: #0066FE;
            padding: 30px;
            font-size: 15px;

            .title {
                font-size: 24px;
                margin-bottom: 30px;
            }
        }

        .item-1 {
            background: url(https://oss.isuiyou.cn/61e91cd224d5961e20f6ca5585fdda517f1c733e834a5a05295e06cb64dca877.png) center center no-repeat;
            background-size: cover;
        }

        .item-2 {
            background: url(https://oss.isuiyou.cn/917e2c642a234803b2152e7e123f7425701e21f05c6e747efd3e6e66f992c73a.png) center center no-repeat;
            background-size: cover;
        }

        .item-3 {
            background: url(https://oss.isuiyou.cn/70a36d69277b4a9535efd20299809cce20d12348e00ad687dacb5bb4b690992e.png) center center no-repeat;
            background-size: cover;
        }
    }

    .about-bottom-view {
        font-size: 38px;
        gap: 20px;
        margin-bottom: 100px;

        .item {
            border-radius: 25px;
            width: 673px;
            height: 480px;
            padding: 50px;

            &:nth-child(1) {
                color: #1D1D1F;
                background-color: #fff;
                background-image: url(https://oss.isuiyou.cn/6533a837773ca93bd3b84fca0916793840ca248afbb9135714750c8b817f56c5.png);
                background-position: right bottom;
                background-repeat: no-repeat;
                background-size: 531px 383px;

                .title {
                    background: linear-gradient(101deg, #765BE8 15%, #2B6AF8 84%);
                    background-clip: text;
                    text-fill-color: transparent;
                }
            }

            &:nth-child(2) {
                color: #fff;
                position: relative;
                background: #1D1D1F;

                .title {
                    color: #FFBC01;
                }

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 200px;
                    right: 54px;
                    width: 550px;
                    height: 74px;
                    background: url(https://oss.isuiyou.cn/5eeb4a7aa9c6bf81a82eaf83be9aa1586b169cf636368adcfcad5791c7893fbe.png) center center no-repeat;
                    background-size: contain;

                }

                &.active {
                    &::before {
                        animation: bottom2Top 2s 1;
                    }

                    &::after {
                        animation: top2Bottom 2s 1;
                    }
                }

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 150px;
                    width: 312px;
                    height: 74px;
                    right: 173px;
                    background: url(https://oss.isuiyou.cn/a5871d70607aeffbf65080689e9438e5fac94ea5e9c324adba9ca0c80f323f35.png) center center no-repeat;
                    background-size: contain;
                }

                @keyframes bottom2Top {
                    0% {
                        bottom: 120px;
                    }

                    10% {
                        bottom: 150px;
                    }

                    100% {
                        bottom: 200px;
                    }
                }

                @keyframes top2Bottom {
                    0% {
                        bottom: 220px;
                    }

                    10% {
                        bottom: 200px;
                    }

                    100% {
                        bottom: 150px;
                    }
                }
            }
        }

    }
}
</style>